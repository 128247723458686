<template>
  <div class="box">
    <div class="title">
      <p @click="back">
        <van-icon class="icon" name="arrow-left" />
      </p>
      <header>银行</header>
    </div>
    <div style="height: 40px"></div>
    <!-- user信息 -->
    <Act />

    <div class="main" v-if="!loading">
      <div class="card banner-card">
        <div class="avatar" v-if="allowed">
          <img :src="image" alt="">
        </div>
        <div class="message" v-if="allowed">
          每次可向财神许愿2000积分
        </div>
        <div class="message" v-else>
          竞猜币为0且没有任何正在投注的题目时，可向财神许愿2000积分
        </div>
        <button @click="makeVow" v-if="allowed">许愿2000积分</button>
        <button disabled v-else>借款</button>
      </div>

      <div class="card redeem-vow-card" >
        <div class="card-title">还愿</div>
        <div class="card-body" v-if="loan_num > 0">
          <span style="color: #838C8F">输入积分</span>
          <input type="number" v-model="inputVal" placeholder="输入随意金额">
          <button @click="redeemVow">还愿</button>
        </div>
        <div v-else style="line-height: 92px; font-size: 12px; color: #838C8F">还未许愿</div>
      </div>

      <div class="card account-book-card">
        <div class="card-title">财神账本

          <span>总额：{{count}}</span>
        </div>
        <div class="card-body">
          <div class="log-record" v-for="(record, index) in logs" :key="index">
            <div class="log-main">
              <span>{{name}}向财神</span>
              <span>{{record.type === 1 ? '许愿' : '还愿' }}</span>
              <span>了</span>
              <span :style="`color: ${record.type === 1 ? '#E96346' : '#4AA6E7'}`" >
                {{record.integrate}}
              </span>
              <span>积分</span>
            </div>
            <dir class="log-time">{{record.created_at}}</dir>
          </div>
          <div style="line-height: 128px; font-size: 12px; color: #838C8F" v-if="logs.length === 0">暂无记录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Popup, Notify, Dialog, Button } from "vant";
import Act from "../../components/outLogin/index.vue";

import image from "@/assets/images/caishen.png"

Vue.use(Popup);
Vue.use(Icon);
export default {
  components: {
    Act,
  },
  data() {
    return {
      loading: true,
      allowed: false,
      loan_num: 0,
      inputVal: "",
      image,
      logs: [],
      count: 0,
      name: "",
    }
  },
  created() {
    this.getUser();
    this.getIcan();
    this.getLog();
  },
  mounted(){
    console.log(this.$store)
  },
  methods: {
    //返回竞猜页面
    back() {
      // this.$router.go(-1);
      this.$router.push({
        name: "guessResult",
      });
    },
    async getUser() {
      const res = await this.$api.UserInfo.getUser();
      if (res.errorCode == 1000) {
        this.$store.commit("changeLoginState", res.data);
      }
    },
    makeVow(){
      this.$api.Bank.getBank().then((res) => {
        if (res.errorCode !== 1000) {
          Notify({type: 'danger', message: res.errorMessage});
          return
        }
        this.getUser();
        this.getLog();
        this.getIcan()
        Notify({type: 'primary', message: '许愿成功'});
      })
    },
    redeemVow(){
      const inputedNumber = Number(this.inputVal);

      if (isNaN(inputedNumber)) {
        return Notify({type: 'warning', message: '请输入正确的金额'});
      }

      if ((inputedNumber % 1 !== 0) || inputedNumber <= 0) {
        return Notify({type: 'warning', message: '金额只能为整数且大于0'});
      }

      this.$api.Bank.returnBank({integrate: inputedNumber}).then((res) => {
        if (res.errorCode !== 1000) {
          Notify({type: 'danger', message: res.errorMessage});
          return
        }
        Notify({type: 'primary', message: '还愿成功'});
        this.getUser();
        this.getLog();
        this.getIcan()
        this.inputVal = ""
      })
    },
    getLog(){
      this.$api.Bank.getBankLog().then((res) => {
        if (res.errorCode !== 1000) {
          Notify({type: 'danger', message: res.errorMessage});
          return
        }
        this.logs = res.data.data
        this.count = res.data.total
        this.name = res.data.name
      })
    },
    getIcan(){
      this.$api.Bank.canBank().then((res) => {
        if (res.errorCode !== 1000) {
          Notify({type: 'danger', message: res.errorMessage});
          return
        }
        this.loading = false;
        this.allowed = res.data.type === 1;
        this.loan_num = res.data.loan_num
      })
    }
  }
};
</script>

<style lang="less" scoped>
.icon {
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  top: 12px;
  left: 10px;
  z-index: 50000;
}
.box {
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 16px;
  box-sizing: border-box;
  padding-bottom: 20px;
  .surplus {
    height: 40px;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    .div {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #ddd;
      background-repeat: no-repeat;
      background-size: cover;
      img {
        margin-right: 9px;
      }
    }
    span {
      color: #fdc261;
    }
  }

  .title {
    width: 100%;
    text-align: left;
    // padding: 10px 0;
    background: #f8f8f8;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    min-height: 40px;
    box-sizing: border-box;
    top: 0;
    .icon {
      vertical-align: middle;
      font-weight: bold;
      font-size: 20px;
    }
    span {
      vertical-align: middle;
      margin-left: 15px;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 320px) {
  .box {
    font-size: 14px;
  }
  .myBet,
  .myBet2 {
    font-size: 14px !important;
  }
  .title > span {
    font-size: 14px !important;
  }
}


.main {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  margin-top: 10px;
  .card {
    width: 100%;
    background: white;
    box-shadow: 0 0 5px 0 #ddd;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}

.banner-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px;

  .avatar {
    width: 88px;
    height: 88px;
    border-radius: 88px;
    border: 3px solid #ffd571;
    margin-bottom: 19px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 100%;
    }
  }

  button {
    margin: 0;
    height: 40px;
    background: #e96346;
    border: none;
    width: 90%;
    color: white;
    margin-top: 19px;
    font-size: 16px;
    &[disabled] {
      background: linear-gradient(45.00deg, #cad1e2 0%, #dae0ed 100%);
    }
  }
}

.redeem-vow-card {
  .card-title {
    line-height: 46px;
    border-bottom: solid 1px #e5e5e5;
    text-align: left;
    padding: 0 12px;
    
  }
  .card-body {
    padding: 28px 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    span {
      white-space: nowrap;
    }
    input {
      flex: 1 1;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #dae0ed;
      text-align: right;
      padding: 0px 12px;
      min-width: 0px;
      &::placeholder{
        color: #DAE0ED;
      }
    }

    button {
      width: 116px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #e96346;
      color: #e96346;
      background: none;
    }
  }
}

.account-book-card {
  .card-title {
    line-height: 46px;
    border-bottom: solid 1px #e5e5e5;
    text-align: left;
    padding: 0 12px;
    display: flex;
    span {
      color:#e96346;
      margin-left: auto;
    }
  }
  .card-body {
    padding: 12px;

    .log-record {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 14px;
      color: #838C8F;
      .log-main {
        flex: 1 1;
      }
    }
  }
}
</style>
